import { fromEvent } from 'rxjs';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import { CatalogInterface } from '../interfaces/catalog.interface';
import { PloneService } from '../services/plone.service';
import Swiper, { Grid, Lazy, Navigation, Pagination } from 'swiper';
import ArtraApp from '../artra-app';

export class CatalogsController {
  /**
   * Catalogs to display
   */
  galleries: CatalogInterface[];
  /**
   * Service to get catalogs
   */
  ploneService: PloneService;
  /**
   * Library to display galleries
   */
  swiper: Swiper;

  catalogTemplate: HTMLTemplateElement;

  appLinkTarget = '_self';

  constructor(private artraApp: ArtraApp) {
    this.catalogTemplate = document.getElementById('gallery-template') as HTMLTemplateElement;
    this.ploneService = new PloneService(artraApp);
    this.getCatalogs();
  }

  /**
   * Get catalogs and display them
   */
  getCatalogs() {
    this.ploneService.getCatalogs().subscribe(response => {
      this.galleries = response.galleries;
      this.displayCatalogs();
    });
  }

  /**
   * Display catalogs and get window size to set up swiper
   */
  displayCatalogs() {
    const container = document.getElementById('galleries-list');

    this.galleries.forEach((gallery) => {
      const template = this.catalogTemplate.content.cloneNode(true);

      container.appendChild(this.renderGalleryTemplate(template, gallery));
    });

    this.getWindowsSize();
    fromEvent(window, 'resize').subscribe(() => this.getWindowsSize());
    fromEvent(container, 'click').subscribe((event) => {
      const element = event.target as HTMLElement;
      if(element.classList.contains('gallery-item__qr-code')) {
        window.open(element.dataset.url, element.dataset.target || this.appLinkTarget);
      }
    });
  }

  getAppLink(href: string) {
    if (!href) {
      return '';
    }
    const url = new URL(href);
    const galleryId = new URLSearchParams(url.search).get('galerie');
    return window.location.origin + `/appli/?galerie=${galleryId}&link=true&lang=${window.lang}`;
  }

  renderGalleryTemplate(template: Node, galleryData: CatalogInterface): HTMLElement {
    const tmpl = template as HTMLElement;
    const imageEl = tmpl.querySelector('[data-image]') as HTMLElement;
    const qrCodeEl = tmpl.querySelector('[data-qr-code]') as HTMLElement;
    const authorEl = tmpl.querySelector('[data-author]') as HTMLElement;
    const appLinkEl = tmpl.querySelector('[data-app-link]') as HTMLElement;
    const galLinkEl = tmpl.querySelector('[data-gal-link]') as HTMLElement;
    const galleryAppLink = this.getAppLink(galleryData.url)

    imageEl.style.backgroundImage = `url('${galleryData.illustration}')`;
    qrCodeEl.setAttribute('src', galleryData.qr_code);
    qrCodeEl.setAttribute('data-url', galleryAppLink);
    authorEl.innerText = galleryData.name;
    authorEl.setAttribute('title', galleryData.name);
    appLinkEl.setAttribute('href', galleryAppLink);
    appLinkEl.setAttribute('target', this.appLinkTarget);

    const galleryUrl = galleryData.url.replace(/.+=(.+)/, '$1');
    const galleryHostEnv = !this.artraApp.isProd ? 'acc.' : '';
    galLinkEl.setAttribute('href', `https://${galleryHostEnv}gallery.artra.be/${galleryUrl}?lang=${window.lang}`);

    return tmpl;
  }

  /**
   * Use swiper on mobile and tablet
   * Destroy swiper if exist on desktop
   */
  getWindowsSize() {
    const isSwiperInit = this.swiper && !this.swiper.destroyed;

    if (window.innerWidth <= 1200) {
      if (!isSwiperInit) {
        this.setupSlider();
      }
    } else {
      if (isSwiperInit) {
        this.swiper?.destroy();
      }
    }
  }

  /**
   * Setup swiper according to the device size
   */
  setupSlider() {
    this.swiper = new Swiper('.swiper.catalogs', {
      modules: [Pagination, Grid, Navigation, Lazy],
      threshold: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      lazy: true,
      rewind: true,
      breakpoints: {
        320: {
          slidesPerGroup: 1,
          slidesPerView: 1,
          spaceBetween: 20,
          grid: {
            rows: 1,
            fill: 'row',
          },
        },
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          spaceBetween: 20,
          grid: {
            rows: 2,
            fill: 'row',
          },
        },
      },
    });
  }
}
