export class FooterController {

  constructor() {
    this.setCopyrightDate();
    this.setFooterActiveLink();
  }

  setCopyrightDate() {
    document.querySelector('.copyright__date').textContent = new Date().getFullYear().toString();
  }

  /**
   * @deprecated nunjucks render should be considered instead of JS "activation"
   */
  setFooterActiveLink() {
    const menuLinks = document.querySelectorAll('footer a.menu-item');

    menuLinks.forEach((menuLink) => {
      const currentLink = window.location.pathname.split('/').pop();
      const menuLinkHref = menuLink.getAttribute('href').split('/').pop();
      if (menuLinkHref && menuLinkHref === currentLink) {
        menuLink.classList.add('active');
      }
    });
  }
}
